<template>
	<div class="table-template">
		<div class="div-table">
			<table>
				<tr>
					<th v-for="(col, index) in columns" :key="index" tabindex="0">
						{{ col.title }}
					</th>
				</tr>
				<tr v-for="(row, index) in value" :key="index">
					<td v-for="(col, colIndex) in columns" :key="colIndex" tabindex="0">
						<template v-if="isReadonly">{{ row[col.key].value }}</template>
						<template v-else-if="row[col.key].type === 'Text'">
							<i
								class="ivu-icon ivu-icon-md-remove-circle row-delete"
								v-if="!row.isDefaultRow && !disabled && colIndex === 0"
								@click.stop="delRow(index)"
							></i>
							<Input v-model="row[col.key].value" :disabled="disabled" />
						</template>
					</td>
				</tr>
			</table>
		</div>
		<div class="div-footer" v-if="!disabled && !isReadonly">
			<Button icon="ios-add" type="primary" @click="addRow">添加行</Button>
		</div>
	</div>
</template>
<script>
import "./TableComp.less"

export default {
	props: {
		tableItem: {
			type: Object,
			default: () => {}
		},
		value: {
			type: Array,
			default: () => []
		},
		disabled: {
			type: Boolean,
			default: false
		},
		isReadonly: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {}
	},
	computed: {
		columns() {
			return this.tableItem.tableObj.columns
		}
	},
	methods: {
		// 添加行
		addRow() {
			const row = {}
			this.columns.forEach(col => {
				row[col.key] = {
					value: "",
					type: "Text"
				}
			})
			this.value.push(row)
		},
		// 删除行
		delRow(index) {
			this.value.splice(index, 1)
		}
	}
}
</script>
