import { Message } from "view-design"

const getNumOrString = value => {
	if (parseFloat(value) == value) {
		return parseFloat(value)
	}
	return value
}

// 操作方法
const operatorObj = {
	"+": (leftValue, rightValue) =>
		getNumOrString(rightValue) + getNumOrString(leftValue),
	"-": (leftValue, rightValue) =>
		getNumOrString(rightValue) - getNumOrString(leftValue),
	"*": (leftValue, rightValue) =>
		getNumOrString(rightValue) * getNumOrString(leftValue),
	"/": (leftValue, rightValue) =>
		getNumOrString(rightValue) / getNumOrString(leftValue),
	"%": (leftValue, rightValue) =>
		getNumOrString(rightValue) % getNumOrString(leftValue),
	"==": (leftValue, rightValue) => rightValue == leftValue,
	"!=": (leftValue, rightValue) => rightValue != leftValue,
	">": (leftValue, rightValue) =>
		getNumOrString(rightValue) > getNumOrString(leftValue),
	"<": (leftValue, rightValue) =>
		getNumOrString(rightValue) < getNumOrString(leftValue),
	">=": (leftValue, rightValue) =>
		getNumOrString(rightValue) >= getNumOrString(leftValue),
	"<=": (leftValue, rightValue) =>
		getNumOrString(rightValue) <= getNumOrString(leftValue),
	"&&": (leftValue, rightValue) =>
		getNumOrString(rightValue) && getNumOrString(leftValue),
	"||": (leftValue, rightValue) =>
		getNumOrString(rightValue) || getNumOrString(leftValue)
}

// 判断是否是操作符
function isOperator(string) {
	const operatorList = [
		"&&",
		"||",
		">",
		"<",
		">=",
		"=<",
		"==",
		"!=",
		"+",
		"-",
		"*",
		"/",
		"%",
		")",
		// 未使用的操作符
		"="
	]

	return operatorList.indexOf(string) > -1
}

// 获取运算符优先级
function getOperatorLevel(operator) {
	const operatorList = [
		["&&", "||"], // 等级0
		[">", "<", ">=", "=<", "==", "!="], // 等级1
		["+", "-"], // 等级2
		["*", "/", "%"] // 等级3
	]
	let level = 0

	for (let i = 0; i < operatorList.length; i++) {
		const item = operatorList[i]
		if (item.indexOf(operator) > -1) {
			level = i
			break
		}
	}

	return level
}

// 使用操作符，入数据栈
function operatorToData(operatorStack, dataStack) {
	const operatorValue = operatorStack.pop() // 删除最后一个元素，并返回该值。
	const rightValue = dataStack.pop()
	const leftValue = dataStack.pop()

	dataStack.push([operatorValue, leftValue, rightValue])
}

// 递归计算
function loopGetResult(dataStack = []) {
	const operatorValue = dataStack[0]
	if (!operatorObj[operatorValue]) {
		return {
			type: "error",
			msg: "表达式格式错误"
		}
	}
	let leftValue = dataStack[1]
	let rightValue = dataStack[2]
	// 如果是数组，继续递归
	if (Object.prototype.toString.call(leftValue) === "[object Array]") {
		leftValue = loopGetResult(leftValue)
	}

	// 如果是数组，继续递归
	if (Object.prototype.toString.call(rightValue) === "[object Array]") {
		rightValue = loopGetResult(rightValue)
	}

	let result = "false"

	try {
		if (
			leftValue &&
			rightValue &&
			leftValue.indexOf(",") &&
			rightValue.indexOf(",")
		) {
			const leftArr = leftValue.split(",")
			const rightArr = rightValue.split(",")
			if (leftArr.length === rightArr.length) {
				const ret = leftArr.every(item => rightArr.includes(item))
				result = operatorValue === "==" ? ret : !ret
			} else if (operatorValue === "!=") {
				result = true
			} else {
				result = false
			}
		} else {
			result = operatorObj[operatorValue](leftValue, rightValue)
		}
	} catch (e) {
		Message.error("表达式格式错误")
		console.error(e)
	}
	return result
}

// 主方法，计算表单式
function interpreter(string = "", data = {}) {
	const dataStack = [] // 数据栈
	const operatorStack = [] // 操作栈

	if (!string) {
		return false
	}

	const tokens = string.split(" ")

	// 出栈的结束符
	tokens.push(")")
	// 匹配出栈的结束符
	tokens.unshift("(")

	for (let i = tokens.length - 1; i >= 0; --i) {
		const item = tokens[i]

		if (item === "(") {
			// 如果是左括号，在遇到右括号之前，一直进行操作符出栈
			while (operatorStack[operatorStack.length - 1] !== ")") {
				operatorToData(operatorStack, dataStack)
			}
			// 抵消一个左括号
			operatorStack.pop()
		} else if (isOperator(item)) {
			// 如果是操作符，进行操作符入栈
			if (item === "=") {
				return {
					type: "error",
					msg: "表达式格式错误"
				}
			}
			if (item !== ")") {
				// 判断运算符优先级
				const level1 = getOperatorLevel(item)
				const level2 = getOperatorLevel(operatorStack[operatorStack.length - 1])
				// 如果有限级小于栈顶的操作符优先级，则先进行计算
				if (level1 < level2) {
					operatorToData(operatorStack, dataStack)
				}
			}

			operatorStack.push(item)
		} else {
			// 如果是数据符，进行数据符入栈
			// 如果有此变量，就用变量，没有变量就使用字符串
			if (item in data) {
				dataStack.push(data[item])
			} else {
				dataStack.push(item)
			}
		}
	}

	return loopGetResult(dataStack[0])
}

export default interpreter
